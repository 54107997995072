module.exports = {
  name: 'sportcity',
  imagePath: '/static/images/sportcity',
  logo: {
    mobile: '/static/images/sportcity/logos/logo.svg',
    desktop: '/static/images/sportcity/logos/logo-full.svg',
    desktopLight: '/static/images/sportcity/logos/logo-light.svg',
    desktopWhiteDot: '/static/images/sportcity/logos/logo-full-white.svg',
  },
  social: {
    facebook: 'SportCityNL',
    instagram: 'SportCity',
    tiktok: 'SportCityNL',
  },
  socialLink: {
    facebook: 'https://www.facebook.com/SportCityNL',
    instagram: 'https://www.instagram.com/sportcity/',
    tiktok: 'https://www.tiktok.com/@sportcitynl',
  },
  app: {
    appStore: 'https://apps.apple.com/nl/app/sportcity/id614562456',
    appStoreId: '614562456',
    playStore:
      'https://play.google.com/store/apps/details?id=nl.sportcity.android&hl=nl',
    website: 'https://www.sportcity.nl/app',
  },
  oldSiteUrl: {
    home: 'https://www.sportcity.nl/',
    memberships: 'https://www.sportcity.nl/abonnementen',
  },
  clubs: {
    url: 'https://www.sportcity.nl/clubs',
    slug: 'clubs',
  },
  checkout: {
    hasAddons: false,
    contractPeriod: {
      showPriceDifference: false,
    },
    usesPerfectGym: true,
    showMembershipPrice: true,
    showFitForFriends: true,
    showFourWeeksDiscount: false,
    showFourWeeksPromo: false,
    logoLink: {
      href: 'https://www.sportcity.nl/',
    },
    path: '/checkout',
  },
  copyright: 'SportCity',
  domain: 'https://www.sportcity.nl',
  hostname: 'www.sportcity.nl',
  login: true,
  loginUrl: 'https://www.sportcity.nl/mijn-account',
  mail: {
    app: 'mailto:app@sportcity.nl',
  },
  seo: {
    blockSearchEngines: false,
    metaTitleSuffix: '| SportCity',
  },
  externalClub: {
    id: 28,
    url: {
      home: 'https://www.sportcitycornelisschuyt.nl',
      funnel: 'https://www.sportcitycornelisschuyt.nl/funnel',
    },
  },
  funnel: {
    useHardcodedPrices: {
      'back-to-sport': true,
    },
  },
  brand: 'SportCity',
};
