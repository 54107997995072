import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { Body } from '../components/typography';
import { RichText } from 'src/features/shared/components/rich-text';
import { INLINES } from '@contentful/rich-text-types';
import { Link } from '../components/link';
import { EntryFields } from 'contentful';
import { isDocumentNode } from '../contentful/utils/isDocumentNode';
import { ILinkEntries } from 'src/features/shared/contentful/types/ILinkEntries';
import { isRichText } from '../contentful/utils/isRichText';
import { merge } from 'lodash';

export function useRichTextRenderer(
  lang?: string,
  openLinksInNewTab = false,
  options: Options = {},
  onLinkClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void,
) {
  const defaultOptions: Options = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node) => {
        if (!node.data.target) {
          if (node.content[0]?.nodeType === 'text') {
            return node.content[0].value;
          }
          return null;
        }

        const { file, title: fileTitle } = node.data.target.fields;
        let title = fileTitle;
        if (node.content[0]?.nodeType === 'text') {
          title = node.content[0].value;
        }
        return (
          <Link onClick={onLinkClick} href={file.url} isHtmlTag openInNewTab>
            {title}
          </Link>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        if (!node.data.target) {
          if (node.content[0]?.nodeType === 'text') {
            return node.content[0].value;
          }
          return;
        }

        const { title: entryTitle, slug } = node.data.target.fields;
        const { sys } = node.data.target;

        let title = entryTitle;

        if (node.content[0]?.nodeType === 'text') {
          title = node.content[0].value;
        }
        const isCheckout = sys?.contentType.sys.id === 'checkout';
        const href = isCheckout ? `/checkout/${slug}` : `/${slug}`;

        return (
          <Link
            openInNewTab={openLinksInNewTab}
            onClick={onLinkClick}
            href={href}
          >
            {title}
          </Link>
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        if (!node.data.uri) {
          return null;
        }

        const { uri } = node.data;
        let title;
        if (node.content[0].nodeType === 'text') {
          title = node.content[0].value;
        }
        return (
          <Link href={uri} onClick={onLinkClick}>
            {title}
          </Link>
        );
      },
    },
  };

  function renderOptions(links: ILinkEntries): Options {
    // create an entry map
    const entryMap = new Map();

    // loop through the inline linked entries and add them to the map
    for (const entry of links.entries.hyperlink) {
      if (entry) {
        entryMap.set(entry.sys.id, entry);
      }
    }

    return {
      renderNode: {
        [INLINES.ENTRY_HYPERLINK]: (node) => {
          // find the entry in the entryMap by ID
          const entry = entryMap.get(node.data.target.sys.id);

          // render the entries as needed
          if (entry && entry.__typename === 'ContentPage') {
            let title = entry.title;

            if (node.content[0]?.nodeType === 'text') {
              title = node.content[0].value;
            }

            return openLinksInNewTab ? (
              <Link href={`/${entry.slug}`} openInNewTab>
                {title}
              </Link>
            ) : (
              <Link href={`/${entry.slug}`}>{title}</Link>
            );
          }
        },
      },
    };
  }

  const combinedOptions = merge(options, defaultOptions);

  const renderRichText = (
    richText: EntryFields.RichText,
    links?: ILinkEntries,
  ) => {
    if (!isDocumentNode(richText)) {
      return null;
    }

    if (richText && links) {
      return documentToReactComponents(richText, renderOptions(links));
    }

    return documentToReactComponents(richText, combinedOptions);
  };

  const renderSectionDescription = (
    description: string | EntryFields.RichText,
    className?: string,
  ) => {
    if (!isRichText(description)) {
      return (
        <Body component="div" className={className}>
          {description}
        </Body>
      );
    }
    return (
      <RichText className={className}>{renderRichText(description)}</RichText>
    );
  };

  return {
    renderRichText,
    renderSectionDescription,
  };
}
