import styles from './TopBanner.module.scss';
import * as SessionStorage from '@features/bring-a-friend/utils/session-storage';
import classNames from 'classnames';
import Icons from 'features/shared/components/icons';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { useIntl } from 'src/domains/i18n';
import {
  Body,
  FontColor,
  FontWeight,
} from 'src/features/shared/components/typography';
import { ITopBanner } from 'src/features/shared/contentful/types/ITopBanner';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { isAvailable } from 'src/utils/is-available';

export interface ITopBannerProps {
  topBanner: ITopBanner;
  onClose: () => void;
}

export const TopBanner = ({ topBanner, onClose }: ITopBannerProps) => {
  const { formatMessage } = useIntl();
  const { icon, description, backgroundColor = 'Yellow' } = topBanner;
  const Icon = Icons[icon];
  const pathname = usePathname()?.replace('/', '');
  const sessionStorageValue = SessionStorage.getItem(
    `showTopBanner-${pathname}`,
  );

  const [showTopBanner, setShowTopBanner] = useState<boolean>(
    isAvailable(sessionStorageValue) ? sessionStorageValue === 'true' : true,
  );

  const closeTopBanner = () => {
    SessionStorage.setItem(`showTopBanner-${pathname}`, 'false');
    setShowTopBanner(false);
    pushGA4Event(GA4_EVENTS.trackEvent, { event_name: 'close_promobanner' });
    onClose && onClose();
  };

  const pushEvent = () =>
    pushGA4Event(GA4_EVENTS.trackEvent, {
      event_name: 'click_promobanner',
    });

  const { renderRichText } = useRichTextRenderer('nl', false, {}, pushEvent);

  if (!showTopBanner || !description) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.yellow]: backgroundColor === 'Yellow',
        [styles.black]: backgroundColor === 'Black',
      })}
    >
      <div className={styles.wrapper}>
        {icon && <Icon className={styles.icon} />}
        <Body
          component="div"
          className={classNames(styles.description, {
            [styles.yellow]: backgroundColor === 'Yellow',
          })}
          color={
            backgroundColor === 'Yellow'
              ? FontColor.Neutral01
              : FontColor.Neutral06
          }
          fontWeight={FontWeight.Medium}
        >
          {renderRichText(description)}
        </Body>
      </div>
      <button
        onClick={closeTopBanner}
        aria-label={formatMessage('general.close')}
        className={styles.closeIcon}
      >
        <Icons.Cross />
      </button>
    </div>
  );
};
