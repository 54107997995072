import { useIntl } from 'domains/i18n';
import { ComponentProps, useMemo } from 'react';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { Button } from '../button';
import {
  CHECKOUT_URL,
  DEFAULT_CHECKOUT,
} from 'src/features/checkout/util/constants';

interface IJoinButtonProps
  extends Omit<ComponentProps<typeof Button>, 'children'> {
  clubId?: number;
}

export function JoinButton({ clubId, ...props }: IJoinButtonProps) {
  const { formatMessage } = useIntl();
  const label = useMemo(() => formatMessage('join'), [formatMessage]);

  const onClick = () => {
    pushGA4Event(GA4_EVENTS.trackEvent, {
      event_name: 'click_cta_header',
    });
  };

  return (
    <Button
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      href={`/${CHECKOUT_URL}/${DEFAULT_CHECKOUT}${
        clubId ? `?clubId=${clubId}` : ''
      }`}
      onClick={onClick}
      isHtmlTag
      size="sm"
      {...props}
    >
      {label}
    </Button>
  );
}
