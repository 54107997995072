'use client';
import styles from './index.module.scss';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { IMappedMenus } from 'src/features/layout/menu/types/IMappedMenus';
import { DesktopNavigationBar } from 'src/features/layout/navigation-bar/desktop';
import { MobileNavigationBar } from 'src/features/layout/navigation-bar/mobile';
import { TopBanner } from 'src/features/shared/components/top-banner';
import { ITopBanner } from 'src/features/shared/contentful/types/ITopBanner';

export interface NavigationBarProps {
  menus: IMappedMenus;
  topBanner?: ITopBanner;
  clubId?: number;
  background?: 'white' | 'transparent' | 'yellow' | 'soft-peach' | 'soft-sand';
  textColor?: 'white' | 'black';
}

export function NavigationBar({
  background = 'white',
  textColor = 'black',
  topBanner,
  ...props
}: NavigationBarProps) {
  const [isTopBannerVisible, setIsTopBannerVisible] = useState(true);
  const [hasScrollChanged, setHasScrollChanged] = useState(false);

  const backgroundOverride = hasScrollChanged ? 'white' : background;
  const textColorOverride = hasScrollChanged ? 'black' : textColor;

  const rootClassName = classNames(styles.rootContainer, {
    [styles.bgWhite]: background === 'white',
    [styles.bgTransparent]: background === 'transparent',
    [styles.bgYellow]: background === 'yellow',
    [styles.bgSoftPeach]: background === 'soft-peach',
    [styles.bgSoftSand]: background === 'soft-sand',
    [styles.textColorWhite]: textColor === 'white',
    [styles.textColorBlack]: textColor === 'black',
    [styles.sticky]: hasScrollChanged,
  });

  const topBannerBgColor =
    topBanner?.backgroundColor || backgroundOverride === 'yellow'
      ? 'Black'
      : 'Yellow';

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 1) {
        setHasScrollChanged(true);
        return;
      }
      setHasScrollChanged(false);
    };
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <NavigationMenu.Root className={rootClassName}>
      <div className={styles.topBannerWrapper}>
        {topBanner && (
          <TopBanner
            onClose={() => setIsTopBannerVisible(false)}
            topBanner={{ ...topBanner, backgroundColor: topBannerBgColor }}
          />
        )}
      </div>
      <MobileNavigationBar
        {...props}
        isTopBannerVisible={isTopBannerVisible}
        background={backgroundOverride}
        textColor={textColorOverride}
        topBanner={topBanner}
        className={classNames(styles.content, styles.mobile)}
      />
      <DesktopNavigationBar
        {...props}
        background={backgroundOverride}
        textColor={textColorOverride}
        className={classNames(styles.content, styles.desktop)}
      />
    </NavigationMenu.Root>
  );
}
