import { Checkout, CheckoutStep } from '../types/checkout';

export const CHECKOUT_URL = 'checkout';
// Contenful SC-3917
export const DEFAULT_CHECKOUT = 'default';
export const DAYPASS_CHECKOUT = 'dagpas';

export const CHECKOUT_STEPS: Record<
  CheckoutStep,
  { id: CheckoutStep; slug: string; shortTitle: string }
> = {
  intro: {
    id: CheckoutStep.intro,
    slug: 'welkom',
    shortTitle: 'Welkom',
  },
  club: {
    id: CheckoutStep.club,
    slug: 'kies-je-club',
    shortTitle: 'Kies je club',
  },
  membership: {
    id: CheckoutStep.membership,
    slug: 'kies-je-lidmaatschap',
    shortTitle: 'Lidmaatschap',
  },
  details: {
    id: CheckoutStep.details,
    slug: 'persoonlijke-info',
    shortTitle: 'Gegevens',
  },
  extras: {
    id: CheckoutStep.extras,
    slug: 'kies-je-extras',
    shortTitle: "Extra's",
  },
  summary: {
    id: CheckoutStep.summary,
    slug: 'overzicht',
    shortTitle: 'Afronden',
  },
};

export const CHECKOUT_STATUS_SLUG: Record<
  Exclude<Checkout['status'], 'created'>,
  string
> = {
  payment_pending: 'wachten-op-betaling',
  processing: 'verwerken',
  failed: 'mislukt',
  completed: 'gelukt',
};

export const CHECKOUT_SESSION_ID_SEARCH_PARAM = 'sessionId';
export const SHOULD_REDIRECT_PARAM = 'shouldRedirect';

export const TERMS_URL = '/algemene-voorwaarden';
export const PAYMENT_INFO_URL = '/automatische-incasso';
export const HOUSE_RULES_URL = '/huisregels';

export const DAYPASS_CONFIG_ID = '0190545f-4a45-752b-888d-3e9a63508e22';
